var _process3, _process3$env;
var __jsx = React.createElement;
import '../themes/flexboxgrid.css';
import '../themes/global.css';
import React from 'react';
import GlobalStateProvider from '../global-state-context';
import withLinkValidation from '../utils/withLinkValidation';
import { NinetailedProvider } from '@ninetailed/experience.js-next';
import { NinetailedPreviewPlugin } from '@ninetailed/experience.js-plugin-preview';
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights';
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import '@contentful/live-preview/style.css';
function MyApp(_ref) {
  var _process, _process$env, _process2, _process2$env, _pageProps$allExperie;
  var Component = _ref.Component,
    pageProps = _ref.pageProps;
  return __jsx(ContentfulLivePreviewProvider, {
    locale: "en-US",
    enableInspectorMode: ((_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true',
    enableLiveUpdates: ((_process2 = process) === null || _process2 === void 0 ? void 0 : (_process2$env = _process2.env) === null || _process2$env === void 0 ? void 0 : _process2$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true'
  }, __jsx(NinetailedProvider, {
    clientId: "0f6ff73a-c172-4c35-b11b-c8a0a2564929",
    environment: "development",
    plugins: [new NinetailedInsightsPlugin(), new NinetailedPreviewPlugin({
      audiences: ((_pageProps$allExperie = pageProps.allExperiences) === null || _pageProps$allExperie === void 0 ? void 0 : _pageProps$allExperie.map(function (experience) {
        return experience.audience;
      }).filter(function (audience) {
        return !!audience;
      })) || [],
      experiences: pageProps.allExperiences || [],
      onOpenAudienceEditor: function onOpenAudienceEditor(audience) {
        alert("Audience ID: ".concat(audience.id, ".")); // TODO: take user to Contentful entry instead
        console.log('Open audience editor', audience);
      },
      onOpenExperienceEditor: function onOpenExperienceEditor(experience) {
        alert("Experience ID: ".concat(experience.id, ".")); // TODO: take user to Contentful entry instead
        console.log('Open experience editor', experience);
      },
      ui: {
        opener: {
          hide: false // default value is false, should we use this to hide it if user is not logged in?
        }
      }
    })],

    componentViewTrackingThreshold: 2000,
    requestTimeout: 5000,
    url: "https://experience.ninetailed.co",
    useSDKEvaluation: true
  }, __jsx(GlobalStateProvider, {
    initialData: pageProps
  }, __jsx(Component, pageProps))));
}
export default ((_process3 = process) === null || _process3 === void 0 ? void 0 : (_process3$env = _process3.env) === null || _process3$env === void 0 ? void 0 : _process3$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true' ? withLinkValidation(MyApp) : MyApp;